import React, { useEffect, useState } from 'react'
import Layout from '../components/App/Layout'
import PageBanner from '../components/Common/PageBanner'
import GalleryDetailsContent from '../components/GalleryDetails/GalleryDetailsContent'
import { getGallery } from '../api/gallery'
import { navigate } from 'gatsby'

const GalleryDetails = ({ location }) => {
    const [gallery, setGallery] = useState(null)

    const params = new URLSearchParams(location.search)
    const slug = params.get('slug')

    useEffect(() => {
        (async () => {
            try {
                const data = await getGallery(slug)
                setGallery(data.gallery)
            } catch (error) {
                navigate('/404')
            }
        })()
    }, [slug])

    if (!gallery) return null

    return (
        <Layout title={gallery.title}>
            <PageBanner
                pageTitle='Galería'
                homePageText='Inicio'
                homePageUrl='/'
                activePageText={gallery.title}
            />
            <GalleryDetailsContent
                gallery={gallery}
            />
        </Layout>
    )
}

export default GalleryDetails